<template>
  <div id="restrictDeposit">
    <div class="content_box">
      <div class="inner_max">
        <div class="content_title">
          <h2>{{ $t('restrictDeposit.header') }}</h2>
          <p></p>
        </div>
        <Result :info="true"> <div v-html="$t('restrictDeposit.info')"></div> </Result>
      </div>
    </div>
  </div>
</template>

<script>
import Result from '@/components/Result';

export default {
  name: 'RestrictDeposit',
  components: { Result }
};
</script>
